import { ILoggedInUser, IReducer } from '../../types';
import Actions from '../actions';

const initialState: ILoggedInUser | null = null;

const user = (
	state: typeof initialState = initialState,
	{ type, payload }: IReducer<typeof initialState>
) => {
	switch (type) {
		case Actions.LOGIN:
			return (state = payload);
		default:
			return state;
	}
};

export default user;
