import { AxiosResponse } from 'axios';
import jsFileDownload from 'js-file-download';
import { isEqual, startCase, toLower } from 'lodash';
import Resizer from 'react-image-file-resizer';

import { CDataTableFields, IDgeeRate } from '@/types';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

const reduceLayers = (a, b) => {
	return { ...a, ...b };
};

const getHeaderRow = (sheet: XLSX.WorkSheet): string[] => {
	const headers = [];
	const range = XLSX.utils.decode_range(sheet['!ref']);
	let C: number;
	const R = range.s.r; /* start in the first row */
	/* walk every column in the range */
	for (C = range.s.c; C <= range.e.c; ++C) {
		const cell =
			sheet[
				XLSX.utils.encode_cell({ c: C, r: R })
			]; /* find the cell in the first row */

		let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
		if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

		headers.push(hdr);
	}
	return headers;
};

const sentenceCase = (str: string) => {
	return startCase(toLower(str));
};

const capitalFirstLetter = (str: string) => {
	return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

const filterNoneValues = <T extends IDgeeRate>(rates: T[]): T[] => {
	return rates.map((v) => ({
		...v,
		created_user: v.created_user === 'None' ? '' : v.created_user ?? '',
		effective_date:
			v.effective_date === 'None' ? '' : v.effective_date ?? '',
	}));
};

const numberWithCommas = (x: number): string => {
	const rounded = Math.round(x * 100) / 100;
	return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const excelColumnIndexToName = (columnNumber: number) => {
	// To store result (Excel column name)
	let columnName = [];

	while (columnNumber > 0) {
		// Find remainder
		let rem = columnNumber % 26;

		// If remainder is 0, then a
		// 'Z' must be there in output
		if (rem === 0) {
			columnName.push('Z');
			columnNumber = Math.floor(columnNumber / 26) - 1;
		} // If remainder is non-zero
		else {
			columnName.push(String.fromCharCode(rem - 1 + 'A'.charCodeAt(0)));
			columnNumber = Math.floor(columnNumber / 26);
		}
	}

	return columnName.reverse().join('');
};

const nextChar = (c: string) => {
	return String.fromCharCode(c.charCodeAt(0) + 1);
};

const getTableFields = <T,>(
	table: Array<T>,
	filterKeys: string[] = [],
	addActions: boolean = false,
	addToStart?: string[],
	addToEnd?: string[]
): CDataTableFields[] => {
	if (table && (table?.length ?? 0) > 0) {
		let fields: CDataTableFields[] = Object.keys(table[0])?.map((key) => {
			return {
				key: key as string,
				label: sentenceCase(key ?? ''),
			};
		});

		if (filterKeys?.length > 0) {
			fields = fields.filter((f) => !filterKeys.includes(f.key));
		}

		if (addActions) {
			fields.unshift({
				key: 'actions',
				label: 'Actions',
			});
		}

		if (addToStart?.length > 0) {
			const startActions = addToStart?.map<CDataTableFields>((v) => {
				return {
					key: v,
					label: sentenceCase(v),
				};
			});
			fields.unshift(...startActions);
		}

		if (addToEnd?.length > 0) {
			const endActions = addToEnd?.map<CDataTableFields>((v) => {
				return {
					key: v,
					label: sentenceCase(v),
				};
			});
			fields.unshift(...endActions);
		}

		return fields;
	} else {
		return [];
	}
};

const getFileName = (
	res: AxiosResponse
): { fileName: string; extension: string; contentType: string } => {
	try {
		const contentType = res.headers['content-type'];
		const contentDisposition = res.headers['content-disposition'];
		let fileName: string = '';
		if (
			contentDisposition &&
			contentDisposition?.indexOf('attachment') !== -1
		) {
			var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			var matches = filenameRegex?.exec(contentDisposition);
			if (matches !== null && matches?.length > 0 && matches?.[1]) {
				fileName = matches?.[1]?.replace(/['"]/g, '');
			}
		}

		const extension = fileName?.split('.').pop() ?? '';

		return {
			fileName,
			extension,
			contentType,
		};
	} catch (error) {
		console.error(error);
	}
};

/**
 * Download File using JSFileDownload
 * @code {responseType: 'blob'} Must be set
 * @param res AxiosResponse
 * @param tempFileName Temporary Filename to be used if no filename is found
 * @param callback Callback function
 * @returns boolean
 */
const downloadFile = (
	res: AxiosResponse,
	tempFileName: string,
	callback?: () => void
) => {
	//Check if responseType <blob> is set or nor
	if (res.request?.responseType !== 'blob') {
		toast.error('Response Type Must be Blob');
		return false;
	}

	//Downloading file using js-file-download
	const { fileName, contentType, extension } = getFileName(res);

	if (callback) {
		callback();
	}

	try {
		console.log('Extension', { fileName, contentType, extension });
		if (extension !== '') {
			jsFileDownload(res.data, fileName ?? tempFileName, contentType);
			return true;
		} else {
			toast.error('Unable to download document');
			return false;
		}
	} catch (err) {
		console.error('Unable to download file', err);
		return false;
	}
};

const removeMultipleSpaces = (s: string) => s?.replaceAll(/\s\s+/g, ' ');

const getCookie = (name: string) => {
	// Split cookie string and get all individual name=value pairs in an array
	var cookieArr = document.cookie.split(';');

	// Loop through the array elements
	for (var i = 0; i < cookieArr.length; i++) {
		var cookiePair = cookieArr[i].split('=');

		/* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
		if (name === cookiePair[0].trim()) {
			// Decode the cookie value and return
			return decodeURIComponent(cookiePair[1]);
		}
	}

	// Return null if not found
	return null;
};

const compressAndGetBase64 = (file: File) => {
	return new Promise<string>((resolve, reject) => {
		console.log('file type', file.type);
		if (file.type === 'image/svg+xml') {
			const reader = new FileReader();

			reader.onload = (event) => {
				if (typeof event.target.result === 'string') {
					resolve(event.target.result);
				}
			};

			reader.onerror = (event) => {
				reject(event.target.error.message);
			};

			reader.readAsDataURL(file);
		} else {
			Resizer.imageFileResizer(
				file,
				300,
				300,
				'PNG',
				100,
				0,
				(uri: string) => {
					resolve(uri);
				},
				'base64'
			);
		}
	});
};

const isLatitudeY = (num: number) =>
	!isNaN(num) && isFinite(num) && Math.abs(num) <= 90;

const isLongitudeX = (num: number) =>
	!isNaN(num) && isFinite(num) && Math.abs(num) <= 180;

const getBase64 = (data: string, mime: string = 'image/png') => {
	return `data:${mime};base64,${data}`;
};

export const debouncedFilterOptions = {
	equalityFn: (left, right) => {
		return isEqual(left, right);
	},
};

export {
	reduceLayers,
	getHeaderRow,
	sentenceCase,
	filterNoneValues,
	numberWithCommas,
	excelColumnIndexToName,
	nextChar,
	getTableFields,
	downloadFile,
	getFileName,
	removeMultipleSpaces,
	getCookie,
	compressAndGetBase64,
	isLatitudeY,
	isLongitudeX,
	getBase64,
};
