import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import storage from 'redux-persist/lib/storage/session';
import { encryptTransform } from 'redux-persist-transform-encrypt';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { configureStore } from '@reduxjs/toolkit';

import appReducer from '../reducers';
import Actions from '@/redux/actions';
import { IChangeState, ILoggedInUser, IReducer } from '@/types';

const rootReducer = (state, action: IReducer) => {
	if (action.type === Actions.LOGOUT) {
		// for all keys defined in your persistConfig(s)
		storage.removeItem('persist:root');
		storage.removeItem('token');
		// storage.removeItem('persist:otherKey')

		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};

const persistConfig = {
	key: 'root',
	storage: storage,
};

//Redux persist with encryption
const persistedReducer = persistReducer(
	{
		transforms: [
			encryptTransform({
				secretKey: '9ej%7b6%lh67-j02spn)4l8kprkc-b3f4qenlaakyig@-ndx8d',
				onError: function (error) {
					// Handle the error.
					console.error('Error in Encrypting', error);
				},
			}),
		],
		...persistConfig,
	},
	rootReducer
);

//Adding Redux devtools support
const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV === 'development' ? true : false,
});

const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof rootReducer>;

export type RootState = {
	changeState: IChangeState;
	user: ILoggedInUser;
	loading: boolean;
};

export type AppDispatch = typeof store.dispatch;

export { store, persistor };
