import storage from 'redux-persist/lib/storage';

export const saveToken = (token: string) => {
	return storage.setItem('token', token);
};

export const getToken = () => {
	return storage.getItem('token');
};

export const removeToken = () => {
	return storage.removeItem('token');
};
