import { IChangeState } from '@/types';
import Actions from '../actions';

const initialState: IChangeState = {
	sidebarShow: 'responsive',
	asideShow: false,
	darkMode: true,
};

const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case Actions.SET:
			return { ...state, ...rest };
		default:
			return state;
	}
};

export default changeState;
