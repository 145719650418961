import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { apiUrl } from 'src/environment';
import { Loader } from 'src/reusable';
import { Constants } from 'src/utils';
import Swal from 'sweetalert2';
import Actions from './redux/actions';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import './scss/style.scss';
import { IFailed, IResponse } from './types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getToken } from '@/utils/StorageService';
import { api } from '@/api';
//import ToolsLayout from './views/tools/ToolsLayout';
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));

const App = () => {
	const dispatch = useAppDispatch();

	const user = useAppSelector((state) => state.user);
	const loading = useAppSelector((state) => state.loading);
	const [requests, setRequests] = useState<number>(0);

	useEffect(() => {
		if (requests > 0) {
			//start loading
			dispatch({ type: Actions.SET_LOADING });
		} else {
			//stop loading
			dispatch({ type: Actions.REMOVE_LOADING });
		}
	}, [dispatch, requests]);

	const logout = async () => {
		try {
			dispatch({ type: Actions.LOGOUT });
			// const res = await api.get<IResponse<string>>(apiUrl + '/logout');

			// if (res.status === 200 && res.data?.status === true) {
			// 	dispatch({ type: Actions.LOGOUT });
			// } else {
			// 	Swal.fire({
			// 		title: 'Error',
			// 		text: res?.data?.error ?? 'Unable to logout',
			// 		icon: 'error',
			// 	});
			// }
		} catch (error) {
			const err = error as AxiosError<IFailed>;
			console.error(err);
			Swal.fire({
				title: 'Error',
				text: err?.response?.data?.error ?? 'Unable to logout',
				icon: 'error',
			});
		}
	};

	// useEffect(()=>{
	// 	// turning stylesheet off
	// 	const stylesheet = document.styleSheets[0];
	// 	stylesheet.disabled = true;

	// 	console.log(location);
	// }, [location])

	useEffect(() => {
		api.interceptors.request.clear();
		api.interceptors.response.clear();

		api.interceptors.request.use(
			async (config) => {
				const token = await getToken();

				if (token) {
					// console.log('Adding Authorization Header');
					config.headers['Authorization'] = `Bearer ${token}`;
				}

				if (
					Constants.WHITELIST_URLS.filter((url) =>
						config.url?.toString()?.includes(url?.toString())
					).length === 0 &&
					loading !== true
				) {
					setRequests((req) => req + 1);
				}

				return config;
			},
			(error) => {
				const e = error as AxiosError<IFailed>;
				return Promise.reject(e);
			}
		);

		api.interceptors.response.use(
			(response) => {
				setRequests((req) => (req >= 1 ? req - 1 : req));

				return response;
			},
			(error) => {
				const err = error as AxiosError;
				console.log('Error Interceptor', err);
				// dispatch({ type: Actions.REMOVE_LOADING });
				setRequests((req) => (req >= 1 ? req - 1 : req));

				if (err?.response?.status === 401) {
					toast.error('Session Expired! Please login again.');
					logout();
				}

				if (
					err?.code === 'ECONNABORTED' &&
					err.message.includes('timeout')
				) {
					toast.error('Request Timed Out!');
					return Promise.reject(error);
				}

				const e = error as AxiosError<IFailed>;
				return Promise.reject(e);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ToastContainer
				theme="dark"
				pauseOnHover={false}
				pauseOnFocusLoss={false}
				autoClose={3000}
				limit={5}
			/>
			<Router>
				<React.Suspense fallback={<Loader />}>
					<Switch>
						<Route
							exact
							path="/login"
							name="Login Page"
							render={(props) => <Login {...props} />}
						/>
						<Route
							path="/"
							name="Home"
							render={(props) => <TheLayout {...props} />}
						/>
					</Switch>
				</React.Suspense>
			</Router>
		</>
	);
};

export default App;
