import 'core-js';
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './polyfill';
// import { createRoot } from "react-dom/client";
import { icons } from './assets/icons';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@/redux/store';
// import { pdfjs } from 'react-pdf';

import App from './App';

React.icons = icons;

// pdfjs.GlobalWorkerOptions.workerSrc =
// 	'https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.js';

if (process !== undefined && process.env.NODE_ENV !== 'development') {
	console.log = () => {};
}

const BaseApp = () => (
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

//For React 17

ReactDOM.render(<BaseApp />, document.getElementById('root'));

//For React 18 (enables concurrent mode)

// const root = createRoot(document.getElementById("root"));
// root.render(<BaseApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
