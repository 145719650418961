const Divider = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 1,
        backgroundColor: "var(--secondary-dark-theme)",
        margin: "20px 0",
      }}
    />
  );
};

export default Divider;
