import { combineReducers } from '@reduxjs/toolkit';
import changeState from './ChangeState.reducer';
import loading from './Loading.reducer';
import user from './Users.reducer';

const appReducer = combineReducers({
	changeState,
	user,
	loading,
});

export default appReducer;
