const URLs = {
	getPortalItem: (portalUrl: string, item_id: string, token: string) =>
		`${portalUrl}/sharing/rest/content/items/${item_id}${
			item_id.includes('?') ? '&' : '?'
		}f=json&token=${token}`,
	getTokenedUrl: (baseUrl: string, token: string) =>
		`${baseUrl}${baseUrl?.includes('?') ? '&' : '?'}token=${token}&f=json`,
	restURL: (portalUrl: string) =>
		`${portalUrl}/sharing/rest/generateToken?f=json`,
	searchLDAP: (url: string, email: string) => `${url}?emailid=${email}`,
	ldapLogin: (url: string) => `${url}`,
	getModuleList: (url: string, category: string) =>
		`${url}?category=${category}`,
	getBoqList: (url: string, user_name: string) =>
		`${url}?field=user_name&input_value=${user_name}`,
	updateBoq: (url: string, experience_uuid: string) =>
		`${url}?experience_UUID=${experience_uuid}`,
	deleteBoq: (url: string, type: string, experience_uuid: string) =>
		`${url}?name=${type?.replaceAll(
			' ',
			''
		)}&experience_UUID=${experience_uuid}`,
	getBackendConfig: (url: string) => `${url}/backendconfig`,
	getResultData: (url: string, type: string, experience_uuid: string) =>
		`${url}?name=${type}&experience_UUID=${experience_uuid}`,
	getUsers: (url: string, fieldName: string) => `${url}?require=${fieldName}`,
	submitJob: (baseUrl: string, token: string) =>
		`${baseUrl}/submitJob${
			baseUrl?.includes('?') ? '&' : '?'
		}token=${token}&f=json`,
	isLocalHost: () =>
		window.location.origin.includes('localhost') ||
		window.location.origin.includes('ta.test'),
};

export default URLs;
