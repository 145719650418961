enum Actions {
	SET_EXPERIENCE = 'SET EXPERIENCE',
	REMOVE_EXPERIENCE = 'REMOVE EXPERIENCE',
	SET_MODULES = 'SET MODULES',
	REMOVE_MODULES = 'REMOVE MODULES',
	SET = 'set',
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
	SET_LAYER = 'SET_LAYER',
	ADD_LAYER = 'ADD_LAYER',
	REMOVE_LAYER = 'REMOVE_LAYER',
	CHANGE_LAYER = 'CHANGE_LAYER',
	SET_URLS = 'SET_URLS',
	GET_URLS = 'GET_URLS',
	SET_LOADING = 'SET_LOADING',
	REMOVE_LOADING = 'REMOVE_LOADING',
	SET_PROJECT = 'SET_PROJECT',
	REMOVE_PROJECT = 'REMOVE_PROJECT',
	SET_ROUTE = 'SET_ROUTE',
	REMOVE_ROUTE = 'REMOVE_ROUTE',
	SAVE_LAYERS = 'SAVE_LAYERS',
	REMOVE_LAYERS = 'REMOVE_LAYERS',
	SAVE_LOGS = 'SAVE_LOGS',
	REMOVE_LOGS = 'REMOVE_LOGS',
	SET_LAYERS_PROCESSING = 'SET_LAYERS_PROCESSING',
	REMOVE_LAYERS_PROCESSING = 'REMOVE_LAYERS_PROCESSING',
	SAVE_PROCESSING_LAYERS = 'SAVE_PROCESSING_LAYERS',
	REMOVE_PROCESSING_LAYERS = 'REMOVE_PROCESSING_LAYERS',
	SET_TABLE_FIELDS = 'SET_TABLE_FIELDS',
	REMOVE_TABLE_FIELDS = 'REMOVE_TABLE_FIELDS',
	SET_RECLASSIFICATION_PROCESSING = 'SET_RECLASSIFICATION_PROCESSING',
	REMOVE_RECLASSIFICATION_PROCESSING = 'REMOVE_RECLASSIFICATION_PROCESSING',
	SET_AOI_PROCESSING = 'SET_AOI_PROCESSING',
	REMOVE_AOI_PROCESSING = 'REMOVE_AOI_PROCESSING',
	SET_ROUTE_PROCESSING = 'SET_ROUTE_PROCESSING',
	REMOVE_ROUTE_PROCESSING = 'REMOVE_ROUTE_PROCESSING',
	SET_SIMPLIFICATION_PROCESSING = 'SET_SIMPLIFICATION_PROCESSING',
	REMOVE_SIMPLIFICATION_PROCESSING = 'REMOVE_SIMPLIFICATION_PROCESSING',
	SET_TOWER_PLACEMENT_PROCESSING = 'SET_TOWER_PLACEMENT_PROCESSING',
	REMOVE_TOWER_PLACEMENT_PROCESSING = 'REMOVE_TOWER_PLACEMENT_PROCESSING',
	ADD_ASYNC_JOB = 'ADD_ASYNC_JOB',
	REMOVE_ASYNC_JOB = 'REMOVE_ASYNC_JOB',
	ADD_CUSTOM_ROLES = 'ADD_CUSTOM_ROLES',
	REMOVE_CUSTOM_ROLES = 'REMOVE_CUSTOM_ROLES',
	SET_RR_LAR = 'SET_RR_LAR',
	REMOVE_RR_LAR = 'REMOVE_RR_LAR',
	SET_RELEASE_TO_SURVEY = 'SET_RELEASE_TO_SURVEY',
	REMOVE_RELEASE_TO_SURVEY = 'REMOVE_RELEASE_TO_SURVEY',
	SET_TOWER_SCHEDULE = 'SET_TOWER_SCHEDULE',
	REMOVE_TOWER_SCHEDULE = 'REMOVE_TOWER_SCHEDULE',
	SET_TOWER_SCHEDULE_PROCESSING = 'SET_TOWER_SCHEDULE_PROCESSING',
	REMOVE_TOWER_SCHEDULE_PROCESSING = 'REMOVE_TOWER_SCHEDULE_PROCESSING',
	SET_TOWER_SCHEDULE_FIELDS = 'SET_TOWER_SCHEDULE_FIELDS',
	REMOVE_TOWER_SCHEDULE_FIELDS = 'REMOVE_TOWER_SCHEDULE_FIELDS',
	ADD_MAP_CAPTURE = 'ADD_MAP_CAPTURE',
	REMOVE_MAP_CAPTURE = 'REMOVE_MAP_CAPTURE',
	SET_KML_VERSION = 'SET_KML_VERSION',
	REMOVE_KML_VERSION = 'REMOVE_KML_VERSION',
	SET_KML_PROCESSING = 'SET_KML_PROCESSING',
	REMOVE_KML_PROCESSING = 'REMOVE_KML_PROCESSING',
	SET_ASIDE_TAB = 'SET_ASIDE_TAB',
	REMOVE_ASIDE_TAB = 'REMOVE_ASIDE_TAB',
	SET_MAP_LAYERS = 'SET_MAP_LAYERS',
	REMOVE_MAP_LAYERS = 'REMOVE_MAP_LAYERS',
	CHANGE_MAP_LAYERS = 'CHANGE_MAP_LAYERS',
	SET_SF_EXPERIENCE = 'SET_SF_EXPERIENCE',
	REMOVE_SF_EXPERIENCE = 'REMOVE_SF_EXPERIENCE',
	SET_AOI_EXTENT = 'SET_AOI_EXTENT',
	REMOVE_AOI_EXTENT = 'REMOVE_AOI_EXTENT',
}

// export type IAction = typeof Actions;

export type ReducerAction<T> = {
	type: Actions;
	payload: T;
};

export default Actions;
