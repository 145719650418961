import { StylesConfig } from 'react-select';
import { ICustomSelectOption } from '@/types';

const customStyles: StylesConfig = {
	menu: (provided) => ({
		...provided,
		backgroundColor: '#2a2b36',
	}),
	option: (provided) => ({
		...provided,
		borderBottom: 'rgba(255, 255, 255, 0.5) 1px solid',
		color: 'white',
		backgroundColor: '#2a2b36',
		':hover': {
			color: 'var(--info)',
		},
	}),
	control: () => ({
		// none of react-select's styles are passed to <Control />
		width: '100%',
		display: 'flex',
		backgroundColor: 'rgba(255, 255, 255, 0.05)',
		borderRadius: 5,
		color: 'white',
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		const color = 'white';

		return { ...provided, opacity, transition, color };
	},
	multiValue: (styles) => ({
		...styles,
		color: 'white',
		backgroundColor: '#2a2b36',
	}),
	multiValueLabel: (styles) => ({
		...styles,
		color: 'white',
	}),
	input: (styles) => ({
		...styles,
		color: 'white',
	}),
};

const convertToSelectOption = <T extends unknown>(
	data: Array<T>,
	label: string = '',
	selectAll: boolean = false,
	value: string = ''
): ICustomSelectOption[] => {
	let allData: ICustomSelectOption[] = [];
	if (data && data?.length !== 0) {
		if (typeof data[0] === 'object') {
			if (label === '') {
				throw new Error('Label is required');
			}
			allData = data.map((v): ICustomSelectOption => {
				// console.log('CustomSelectUtils', {
				// 	value,
				// 	label,
				// 	data: (v[value as string] ?? v[label as string]) as string,
				// });

				return {
					value: (v[value as string] ?? v[label as string]) as string,
					label: v[label as string] as string,
				};
			});
		} else if (typeof data[0] === 'string' || typeof data[0] === 'number') {
			allData = data.map((v): ICustomSelectOption => {
				return {
					value: v as string,
					label: v as string,
				};
			});
		}

		if (allData.length !== 0 && selectAll) {
			allData.unshift({
				label: 'Select All',
				value: '*',
			});
		}
	}

	return allData;
};

const getDefaultValue = (value: string): ICustomSelectOption => {
	const data: ICustomSelectOption = {
		value: value ?? '',
		label: value !== '' ? value : 'Please select',
		disabled: (value ?? '') !== '' ? false : true,
	};

	// console.log('Default Value', data);

	return data;
};

export { customStyles, convertToSelectOption, getDefaultValue };
