import axios from 'axios';

export const BASE_URL: string =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:8080/api'
		: 'https://api.smartsage.me';

export const api = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});
