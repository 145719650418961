const Constants = {
	EXCEL_FILE_TYPE:
		'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
	WHITELIST_URLS: [
		'generateToken',
		'jobs',
		'submitJob',
		'startEndAOI',
		'ldapuser',
	],
	MIME_REGEX: /data:(.*);base64/gi,
	EXCEL_FILE_TYPE_DOWNLOAD:
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
	EXCEL_FILE_TYPE_DOWNLOAD_EXTENSION: '.xlsx',
	MOBILE_REGEX: /^(6|7|8|9)\d{9}$/,
	EMAIL_REGEX: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
};

export default Constants;
