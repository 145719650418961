import { CSpinner } from '@coreui/react';

const Loader = () => {
	return (
		<div className="d-center custom-loader">
			<CSpinner color="info" />
		</div>
	);
};

export default Loader;
