import {
	cibBehance,
	cibCcAmex,
	cibCcMastercard,
	cibCcVisa,
	cibDribbble,
	cibFacebook,
	cibFlickr,
	cibGithub,
	cibGooglePay,
	cibInstagram,
	cibLinkedin,
	cibPaypal,
	cibPinterest,
	cibProxmox,
	cibReddit,
	cibSkype,
	cibStackoverflow,
	cibStripe,
	cibTumblr,
	cibTwitter,
	cibVimeo,
	cibVk,
	cibXing,
	cibXPack,
	cibYahoo,
	cibYoutube,
	cifBr,
	cifEs,
	cifFr,
	cifIn,
	cifPl,
	cifUs,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowBottom,
	cilArrowRight,
	cilArrowTop,
	cilAsterisk,
	cilBan,
	cilBasket,
	cilBell,
	cilBold,
	cilBookmark,
	cilCalculator,
	cilCalendar,
	cilChartPie,
	cilCheck,
	cilCheckCircle,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCloudDownload,
	cilCode,
	cilCommentSquare,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilDollar,
	cilDrop,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilEyedropper,
	cilFile,
	cilFullscreen,
	cilFullscreenExit,
	cilGlobeAlt,
	cilGraph,
	cilGrid,
	cilHome,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilInputPower,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMap,
	cilMoon,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPencil,
	cilPeople,
	cilPhone,
	cilPlus,
	cilPrint,
	cilPuzzle,
	cilSave,
	cilScrubber,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilShortText,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilTags,
	cilTask,
	cilTrash,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilWarning,
	cilX,
	cilXCircle,
	cibAddthis,
	cibOpenstreetmap,
	cibStackbit,
	cidCloudUpload,
	cidGrid,
	cidGripDotsVertical,
	cilCheckAlt,
	cilCloudUpload,
	cilExpandDown,
	cilEye,
	cilFileExcel,
	cilFilePdf,
	cilHandPointRight,
	cilInfoCircle,
	cilMapAlt,
	cilMinus,
	cilMinusSquare,
	cilPlusSquare,
	cisCaretBottom,
	cisCaretLeft,
	cisCaretRight,
	cisCaretTop,
	cisCheck,
	cisChevronBottom,
	cisChevronBottomAlt,
	cisChevronDoubleDown,
	cisChevronDoubleDownAlt,
	cisChevronDoubleLeft,
	cisChevronDoubleLeftAlt,
	cisChevronDoubleRight,
	cisChevronDoubleRightAlt,
	cisChevronDoubleUp,
	cisChevronDoubleUpAlt,
	cisChevronLeft,
	cisChevronLeftAlt,
	cisChevronRight,
	cisChevronRightAlt,
	cisChevronTop,
	cisChevronTopAlt,
	cisClone,
	cisCloudDownload,
	cisCloudUpload,
	cisCommentSquareExclamation,
	cisCopy,
	cisExclamationCircle,
	cisFileExcel,
	cisLifeRing,
	cisMap,
	cisMapAlt,
	cisPencil,
	cisPlaylistAdd,
	cisReload,
	cisSave,
	cisTrash,
	cisTrashX,
	cisViewList,
	cisX,
	cisXCircle,
	cilPlusCircle,
	cisPlusCircle,
	cidPlusCircle,
	cidArrowCircleBottom,
	cisArrowCircleBottom,
	cidSync,
	cisSync,
	cilSync,
	cisHandshake,
	cisArrowCircleTop,
	cibMatrix,
	cisSettings,
	cilLibraryBuilding,
	cisLibraryBuilding,
	cidCheck,
	cisHamburgerMenu,
	cisList,
	cisEye,
	cidArrowThickToRight,
	cisCheckAlt,
	cilEyeSlash,
	cisMail,
	cisUpdate,
	cilAccountLogout,
	cisSearch,
	cilSearch,
	cisPlus,
} from '@coreui/icons-pro';

export const icons = Object.assign(
	{},
	{
		cilAlignCenter,
		cilAlignLeft,
		cilAlignRight,
		cilApplicationsSettings,
		cilArrowBottom,
		cilArrowRight,
		cilArrowTop,
		cilAsterisk,
		cilBan,
		cilBasket,
		cilBell,
		cilBold,
		cilBookmark,
		cilCalculator,
		cilCalendar,
		cilCloudDownload,
		cilChartPie,
		cilCheck,
		cilChevronBottom,
		cilChevronLeft,
		cilChevronRight,
		cilChevronTop,
		cilCircle,
		cilCheckCircle,
		cilCode,
		cilCommentSquare,
		cilCreditCard,
		cilCursor,
		cilCursorMove,
		cilDrop,
		cilDollar,
		cilEnvelopeClosed,
		cilEnvelopeLetter,
		cilEnvelopeOpen,
		cilEuro,
		cilEyedropper,
		cilFile,
		cilFullscreen,
		cilFullscreenExit,
		cilGlobeAlt,
		cilGraph,
		cilGrid,
		cilHome,
		cilInbox,
		cilIndentDecrease,
		cilIndentIncrease,
		cilInputPower,
		cilItalic,
		cilJustifyCenter,
		cilJustifyLeft,
		cilLaptop,
		cilLayers,
		cilLightbulb,
		cilList,
		cilListNumbered,
		cilListRich,
		cilLocationPin,
		cilLockLocked,
		cilMagnifyingGlass,
		cilMap,
		cilMoon,
		cilNotes,
		cilOptions,
		cilPaperclip,
		cilPaperPlane,
		cilPencil,
		cilPeople,
		cilPhone,
		cilPrint,
		cilPuzzle,
		cilSave,
		cilScrubber,
		cilSettings,
		cilShare,
		cilShareAll,
		cilShareBoxed,
		cilShieldAlt,
		cilSpeech,
		cilSpeedometer,
		cilSpreadsheet,
		cilStar,
		cilSun,
		cilTags,
		cilTask,
		cilTrash,
		cilUnderline,
		cilUser,
		cilUserFemale,
		cilUserFollow,
		cilUserUnfollow,
		cilX,
		cilXCircle,
		cilWarning,
		cilPlus,
		cilShortText,
		cifUs,
		cifBr,
		cifIn,
		cifFr,
		cifEs,
		cifPl,
		cibSkype,
		cibFacebook,
		cibTwitter,
		cibLinkedin,
		cibFlickr,
		cibTumblr,
		cibXing,
		cibGithub,
		cibStackoverflow,
		cibYoutube,
		cibDribbble,
		cibInstagram,
		cibPinterest,
		cibVk,
		cibYahoo,
		cibBehance,
		cibReddit,
		cibVimeo,
		cibCcMastercard,
		cibCcVisa,
		cibStripe,
		cibPaypal,
		cibGooglePay,
		cibCcAmex,
		cibXPack,
		cibProxmox,
		cibStackbit,
		cilEye,
		cisCaretTop,
		cilHandPointRight,
		cilFilePdf,
		cilFileExcel,
		cisCaretBottom,
		cilInfoCircle,
		cisReload,
		cilMinus,
		cisX,
		cisXCircle,
		cidGripDotsVertical,
		cisLifeRing,
		cisMapAlt,
		cibOpenstreetmap,
		cisViewList,
		cilMapAlt,
		cilPlusSquare,
		cilMinusSquare,
		cilCloudUpload,
		cilCheckAlt,
		cisPencil,
		cisCloudUpload,
		cisCloudDownload,
		cisCaretRight,
		cisCaretLeft,
		cisChevronLeft,
		cisChevronTop,
		cisChevronRight,
		cisChevronBottom,
		cisChevronLeftAlt,
		cisChevronTopAlt,
		cisChevronRightAlt,
		cisChevronBottomAlt,
		cisChevronDoubleLeft,
		cisChevronDoubleUp,
		cisChevronDoubleRight,
		cisChevronDoubleDown,
		cisChevronDoubleLeftAlt,
		cisChevronDoubleUpAlt,
		cisChevronDoubleRightAlt,
		cisChevronDoubleDownAlt,
		cisCheck,
		cilExpandDown,
		cisCommentSquareExclamation,
		cisExclamationCircle,
		cidGrid,
		cisMap,
		cidCloudUpload,
		cibAddthis,
		cisTrash,
		cisTrashX,
		cisCopy,
		cisFileExcel,
		cisPlaylistAdd,
		cisClone,
		cisSave,
		cilPlusCircle,
		cisPlusCircle,
		cidPlusCircle,
		cidArrowCircleBottom,
		cisArrowCircleBottom,
		cilSync,
		cidSync,
		cisSync,
		cisHandshake,
		cisArrowCircleTop,
		cibMatrix,
		cisSettings,
		cilLibraryBuilding,
		cisLibraryBuilding,
		cidCheck,
		cisHamburgerMenu,
		cisList,
		cisEye,
		cidArrowThickToRight,
		cisCheckAlt,
		cilEyeSlash,
		cisMail,
		cisUpdate,
		cilAccountLogout,
		cisSearch,
		cilSearch,
		cisPlus,
	}
);
